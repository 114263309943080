
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'


import toObject from 'dayjs/plugin/toObject'
import isToday from 'dayjs/plugin/isToday'
import weekday from 'dayjs/plugin/weekday'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'


dayjs.extend(updateLocale)

dayjs.extend(toObject)
dayjs.extend(isToday)
dayjs.extend(weekday)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)



// defaultLocale: undefined



export default dayjs
