import { default as _91uid_93GxoNa8jvxBMeta } from "/Users/nficano/github/teachmehipaa/web/pages/compliant-software/[uid].vue?macro=true";
import { default as indexvahKlbkpcxMeta } from "/Users/nficano/github/teachmehipaa/web/pages/compliant-software/index.vue?macro=true";
import { default as indexgXoZy514gyMeta } from "/Users/nficano/github/teachmehipaa/web/pages/index.vue?macro=true";
import { default as privacyUsXeOzmfjwMeta } from "/Users/nficano/github/teachmehipaa/web/pages/privacy.vue?macro=true";
import { default as signupX0hrQ0UPCvMeta } from "/Users/nficano/github/teachmehipaa/web/pages/signup.vue?macro=true";
import { default as terms5DFvG3UcWwMeta } from "/Users/nficano/github/teachmehipaa/web/pages/terms.vue?macro=true";
import { default as component_45stubNev0CvUAmlMeta } from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubNev0CvUAml } from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91uid_93GxoNa8jvxBMeta?.name ?? "compliant-software-uid",
    path: _91uid_93GxoNa8jvxBMeta?.path ?? "/compliant-software/:uid()",
    meta: _91uid_93GxoNa8jvxBMeta || {},
    alias: _91uid_93GxoNa8jvxBMeta?.alias || [],
    redirect: _91uid_93GxoNa8jvxBMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/web/pages/compliant-software/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexvahKlbkpcxMeta?.name ?? "compliant-software",
    path: indexvahKlbkpcxMeta?.path ?? "/compliant-software",
    meta: indexvahKlbkpcxMeta || {},
    alias: indexvahKlbkpcxMeta?.alias || [],
    redirect: indexvahKlbkpcxMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/web/pages/compliant-software/index.vue").then(m => m.default || m)
  },
  {
    name: indexgXoZy514gyMeta?.name ?? "index",
    path: indexgXoZy514gyMeta?.path ?? "/",
    meta: indexgXoZy514gyMeta || {},
    alias: indexgXoZy514gyMeta?.alias || [],
    redirect: indexgXoZy514gyMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacyUsXeOzmfjwMeta?.name ?? "privacy",
    path: privacyUsXeOzmfjwMeta?.path ?? "/privacy",
    meta: privacyUsXeOzmfjwMeta || {},
    alias: privacyUsXeOzmfjwMeta?.alias || [],
    redirect: privacyUsXeOzmfjwMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: signupX0hrQ0UPCvMeta?.name ?? "signup",
    path: signupX0hrQ0UPCvMeta?.path ?? "/signup",
    meta: signupX0hrQ0UPCvMeta || {},
    alias: signupX0hrQ0UPCvMeta?.alias || [],
    redirect: signupX0hrQ0UPCvMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/web/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: terms5DFvG3UcWwMeta?.name ?? "terms",
    path: terms5DFvG3UcWwMeta?.path ?? "/terms",
    meta: terms5DFvG3UcWwMeta || {},
    alias: terms5DFvG3UcWwMeta?.alias || [],
    redirect: terms5DFvG3UcWwMeta?.redirect,
    component: () => import("/Users/nficano/github/teachmehipaa/web/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: component_45stubNev0CvUAmlMeta?.name ?? undefined,
    path: component_45stubNev0CvUAmlMeta?.path ?? "/login",
    meta: component_45stubNev0CvUAmlMeta || {},
    alias: component_45stubNev0CvUAmlMeta?.alias || [],
    redirect: component_45stubNev0CvUAmlMeta?.redirect,
    component: component_45stubNev0CvUAml
  }
]