import { defineStore } from "pinia";

export const useContentStore = defineStore("content", {
  state: () => ({
    _features: undefined,
    _hero: undefined,
    _faq: undefined,
    _testimonials: undefined,
    _competition: undefined,
    _articles: undefined,
    _blog: undefined,
  }),
  getters: {
    features: (state) => state._features,
    hero: (state) => state._hero,
    faqs: (state) => state._faq,
    testimonials: (state) => state._testimonials,
    competition: (state) => [state._competition[1], state._competition[0], state._competition[2]],
    articles: (state) => state._articles,
  },
  actions: {
    async setup() {
      const { client } = usePrismic();
      const [
        { data: hero },
        { data: faq },
        { data: testimonials },
        { data: features },
        { data: competition },
        { data: articles },
      ] = await Promise.all([
        useAsyncData("hero", () => client.getSingle("hero").then((res) => res.data)),
        useAsyncData("faq", () => client.getSingle("faq")),
        useAsyncData("testimonials", () => client.getAllByType("testimonials")),
        useAsyncData("features", () =>
          client.getAllByType("features", {
            orderings: [{ field: "my.features.position", direction: "asc" }],
          }),
        ),
        useAsyncData("competition", () => client.getAllByType("simple-pricing")),
        useAsyncData("articles", () => client.getAllByType("content-landing-pages", { limit: 6 })),
      ]);
      this.$patch({
        _competition: competition.value.map((item) => item.data).sort((a, b) => a.index - b.index),
        _hero: hero,
        _faq: [...faq.value.data.left_column, ...faq.value.data.right_column],
        _testimonials: testimonials.value.map((item) => item.data),
        _features: features.value.map((item) => item.data),
        _articles: articles.value,
      });
    },
  },
  persist: {
    key: "content",
    storage: persistedState.localStorage,
  },
});
