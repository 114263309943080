import { defineStore } from "pinia";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    account: () => {},
    accountId: null,
    firstName: null,
    lastName: null,
    role: null,
    uid: null,
    user: () => {},
    userId: null,
  }),
  getters: {},
  actions: {
    setup(profile) {
      this.$state.account = profile.account;
      this.$state.accountId = profile.accountId;
      this.$state.firstName = profile.firstName;
      this.$state.lastName = profile.lastName;
      this.$state.role = profile.role;
      this.$state.uid = profile.uid;
      this.$state.user = profile.user;
      this.$state.userId = profile.userId;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "profile",
        storage: persistedState.localStorage,
      },
    ],
  },
});
