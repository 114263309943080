import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      uid: null,
      user: null,
      email: null,
      profile: null,
      account: null,
    };
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    isGhost: (state) => !state.user,
    getUser: (state) => state.user,
  },
  actions: {
    setUser(user) {
      this.$state.user = user;
      this.$state.uid = user.uid;
    },
    async registerUser(userData) {
      return await $fetch("/api/user/register", {
        method: "POST",
        body: userData,
      });
    },
    async doesUserExist(email) {
      return await useAsyncData("userCheck", () => {
        return $fetch("/api/user/exists", {
          method: "POST",
          body: JSON.stringify({ email }),
        }).then((res) => {
          return res.exists;
        });
      });
    },
    logout() {
      this.uid = null;
      this.email = null;
    },
  },
  persist: {
    key: "user",
    storage: persistedState.localStorage,
    debug: true,
  },
});
