import { defineStore } from "pinia";

export const useOrgStore = defineStore("org", {
  state: () => {
    return {
      admin: null,
    };
  },
  actions: {
    async doesOrgExist(orgName) {
      return await useAsyncData("orgCheck", () => {
        return $fetch("/api/org/exists", {
          method: "POST",
          body: JSON.stringify({ orgName }),
        }).then((res) => {
          this.$patch({ admin: res.admin });
          return res.exists;
        });
      });
    },
  },
});
