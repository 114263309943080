export const useCompetitors = defineStore("competitor", {
  state: () => ({
    _currentId: "teachmehipaa",
    _currentCompetitor: {
      name: "TeachMeHIPAA",
      id: "teachmehipaa",
      price: "$17.95",
      per: "seat",
      features: [
        "HIPAA certification",
        "Free quiz retakes",
        "Employee participation tracking",
        "Unlimited premium support",
        "Annual certification reminders",
        "Free internal policies ($1,000 value)",
        "Free contract templates",
      ],
    },
    _competitors: [
      {
        name: "TeachMeHIPAA",
        id: "teachmehipaa",
        price: "$17.95",
        per: "seat",
        features: [
          "HIPAA certification",
          "Free quiz retakes",
          "Employee participation tracking",
          "Unlimited premium support",
          "Annual certification reminders",
          "Free internal policies ($1,000 value)",
          "Free contract templates",
        ],
      },
      {
        name: "HIPAATraining",
        id: "hipaatraining",
        price: "$29.99",
        per: "seat",
        add: "+$1,000",
        features: [
          "Internal policies ($1,000 cost)",
          "Requires password sharing across organization",
          "Training content 10+ years old",
          "Reporting in complex spreadsheets",
        ],
      },
      {
        name: "Accountable",
        id: "accountable",
        price: "$4,000",
        per: "year",
        features: [
          "Automatic account creation",
          "Shallow check-the-box training solution",
          "Easy reporting and participation tracking",
          "Free contract and vendor tracking",
        ],
      },
    ],
  }),
  getters: {
    labels: (state) => state._competitors.map((c) => ({ name: c.name, id: c.id })),
    current: (state) => state._currentCompetitor,
  },
  actions: {
    switch(id) {
      if (this._currentId === id) return;
      const details = this.getDetailsById(id);
      this._currentId = id;
      this._currentCompetitor = details;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "competitor",
        storage: persistedState.localStorage,
      },
    ],
  },
});
