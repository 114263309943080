import revive_payload_client_4sVQNw7RlN from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/nficano/github/teachmehipaa/web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/nficano/github/teachmehipaa/web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BEnDUEy4ze from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt-unhead/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_8SbxDRbG6Y from "/Users/nficano/github/teachmehipaa/web/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_tbFNToZNim from "/Users/nficano/github/teachmehipaa/web/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import motion_fmZWau4t0U from "/Users/nficano/github/teachmehipaa/web/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_client_l1e1OoAYCc from "/Users/nficano/github/teachmehipaa/web/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_JbOpvgCHAb from "/Users/nficano/github/teachmehipaa/web/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/Users/nficano/github/teachmehipaa/web/.nuxt/formkitPlugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import view_transitions_client_2LpVRIemQY from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/Users/nficano/github/teachmehipaa/web/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_1UohGbtF8v from "/Users/nficano/github/teachmehipaa/web/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import bus_client_VwprazHOAT from "/Users/nficano/github/teachmehipaa/web/plugins/bus.client.js";
import firebase_client_nS52P0yitD from "/Users/nficano/github/teachmehipaa/web/plugins/firebase.client.js";
import glightbox_client_6quUFNQCSf from "/Users/nficano/github/teachmehipaa/web/plugins/glightbox.client.js";
import gtag_client_R70iZRv6jK from "/Users/nficano/github/teachmehipaa/web/plugins/gtag.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BEnDUEy4ze,
  plugin_client_LcKgStRyi6,
  plugin_8SbxDRbG6Y,
  plugin_tbFNToZNim,
  motion_fmZWau4t0U,
  plugin_client_l1e1OoAYCc,
  plugin_JbOpvgCHAb,
  formkitPlugin_pZqjah0RUG,
  nuxt_plugin_03YjkxYbK5,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  plugin_1UohGbtF8v,
  bus_client_VwprazHOAT,
  firebase_client_nS52P0yitD,
  glightbox_client_6quUFNQCSf,
  gtag_client_R70iZRv6jK
]